@tailwind base;
@tailwind components;
@tailwind utilities;

@keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  
  .animate-character
  {
     text-transform: uppercase;
    background-image: linear-gradient(
      -225deg,
      #fff800 0%,
      #df14db 29%,
      #ff1361 67%,
      #fff800 100%
    );
    background-size: auto auto;
    background-clip: border-box;
    background-size: 200% auto;
    color: #fff;
    background-clip: text;
    text-fill-color: transparent;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: textclip 2s linear infinite;
    display: inline-block;
        font-size: 25px;
        font-weight: 900;
  }
  .animate-button {
    background: linear-gradient(
      -225deg,
      #fff800 0%,
      #df14db 29%,
      #ff1361 67%,
      #fff800 100%
    );
    background-size: 400% 400%;
    animation: gradient-animation 5s ease infinite;
  }
  
  @keyframes gradient-animation {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
  @keyframes textclip {
    to {
      background-position: 200% center;
    }
  }

